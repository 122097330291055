<template>
  <header id="app-header"
          class="header">
    <TheAppHeader v-bind="theAppHeaderProps"
                  @toggle-menu="toggleMenu(true)" />

    <TheAppHeaderMenu v-bind="theAppHeaderMenuProps"
                      @toggle-menu="toggleMenu(false)" />
  </header>
</template>

<script setup lang="ts">
import {
  TheAppHeader,
  ITheAppHeaderProps,
  TheAppHeaderMenu,
  ITheAppHeaderMenuProps,
} from './components';

const contactsStore = useContactsStore();

const {
  address = '',
  workTime = '',
  phones = [],
} = contactsStore.data || {};

const isMenuOpened = ref<boolean>(false);

const theAppHeaderProps = computed<Omit<ITheAppHeaderProps, 'email'>>(() => {
  return {
    address,
    workTime,
    phones,
  };
});

const theAppHeaderMenuProps = computed<ITheAppHeaderMenuProps>(() => {
  return {
    isMenuOpened: isMenuOpened.value,
    phones,
  };
});

function toggleMenu(value: boolean): void {
  isMenuOpened.value = value;
}
</script>

<style lang="scss" scoped>
.header
{
  position: relative;
  margin-bottom: 75px;
  z-index: 20;
}
</style>
