<template>
  <div class="container">
    <div class="header__top">
      <h1 class="header__main-title">
        <NuxtLink to="/"
                  class="header__title-link">
          {{ $t('theHeader.title') }}<br />
          <b
             class="header__title-description">{{ $t('theHeader.description') }}</b>
        </NuxtLink>
      </h1>
      <div class="header__top-center">
        <address class="header__text header__address">{{ address }}</address>
        <span class="header__text">{{ workTime }}</span>
      </div>
      <ul class="header__tel">
        <li v-for="(phone, index) in contactPhones"
            :key="phone.raw"
            class="header__text"
            :class="index === 0 ? 'header__phone1' : ''">
          <a :href="`tel:+7${phone.raw}`"
             class="decoration-none">{{ phone.formatted }}</a>
        </li>
      </ul>
      <BackCallButton class="btn header__btn" />
      <button id="app-header-burger"
              class="header__burger"
              @click="emit('toggle-menu')"></button>
    </div>
    <nav class="header__nav">
      <ul class="header__nav-list">
        <li class="header__nav-item"
            v-for="route in routes"
            :key="route.path">
          <NuxtLink :to="route.path"
                    class="header__nav-link"
                    :class="{
                      'header__nav-link--open': $route.path === route.path
                    }">
            {{ route.navigationTitle }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { BackCallButton } from '../../../Popups';
import type { ITheAppHeaderProps } from './types';

const { routes } = useAppConfig();
const $route = useRoute();
const props = defineProps({
  address: {
    type: String as PropType<ITheAppHeaderProps['address']>,
    required: true,
  },
  workTime: {
    type: String as PropType<ITheAppHeaderProps['workTime']>,
    required: true,
  },
  phones: {
    type: Array as PropType<ITheAppHeaderProps['phones']>,
    required: true,
  },
});

const contactPhones = useContactPhones(props.phones);
const emit = defineEmits(['toggle-menu']);
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
../../../Popups
