<template>
  <section class="pop-up"
           :class="isOpened ? 'pop-up__is-open' : ''">
    <div class="pop-up__align">
      <div class="back-call">
        <button class="pop-up__close-btn"
                @click="$popupEventBus.emit('close-back-call-form-popup')"></button>
        <h2 class="pop-up__title">
          {{ $t('popups.backCallPopup.title') }}
        </h2>
        <VForm class="back-call__form"
              :key="dynamicKey"
              :initial-values="initialValues"
              @submit="submitForm">
          <div class="back-call__form-content">
            <p class="back-call__form-description"
               v-html="$t('popups.backCallPopup.description')"></p>
            <div class="back-call__inputs">
              <VField type="text"
                     name="name"
                     :rules="'required'"
                     :placeholder="$t('popups.backCallPopup.form.name.placeholder')"
                     id="back-call__name"
                     class="contacts__input back-call__input" />
              <VField type="tel"
                     name="phone"
                     :rules="'required|min:18'"
                     :placeholder="$t('popups.backCallPopup.form.phone.placeholder')"
                     class="contacts__input back-call__input"
                     id="back-call__phone"
                     v-maska
                     data-maska="+7 (###) ###-##-##" />
            </div>
          </div>
          <VErrorMessage v-for="fieldName in fieldNames"
                          :key="fieldName"
                          :name="fieldName"
                          as="p"
                          class="error-message"
                          v-slot="{ message }">
            {{ getErrorMessageLocalized(fieldName, message) }}
          </VErrorMessage>
          <button type="submit"
                  class="btn back-call__btn">
            {{ $t('popups.backCallPopup.submitButton') }}
          </button>
        </VForm>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { vMaska } from 'maska';
import type { IContactsFormSchema } from '@/types';

const { create } = useStrapi();
const i18n = useI18n();
const eventBus = usePopupEventBus();

const isOpened = ref(false);

eventBus.on('open-back-call-form-popup', () => isOpened.value = true);
eventBus.on('close-back-call-form-popup', () => isOpened.value = false);

const initialValues: Pick<IContactsFormSchema, 'name' | 'phone'> = {
  name: '',
  phone: '',
};
const fieldNames = Object.keys(initialValues) as (keyof typeof initialValues)[];
const dynamicKey = ref(1);

function getErrorMessageLocalized(fieldName: keyof typeof initialValues, errorMessage: string) {
  return errorMessage.replace(fieldName, `"${i18n.t(`popups.backCallPopup.form.${fieldName}.placeholder`)}"`);
}

async function submitForm(values: typeof initialValues) {
  try {
    await create('requests', {
      ...values,
      text: i18n.t('popups.backCallPopup.message'),
    });
    dynamicKey.value += 1;
    eventBus.emit('close-back-call-form-popup');
    eventBus.emit('open-success-popup');
  } catch (e) {
    eventBus.emit('close-back-call-form-popup');
  }
}
</script>

<style lang="scss" scoped>
@import "../popup";
@import "../back-call";
</style>
