<template>
  <div id="app-header-menu"
       class="menu"
       :class="isMenuOpened ? 'menu__is-active' : ''">
    <div class="container menu__container">
      <div class="menu__close">
        <button id="app-header-menu-close"
                class="menu__close-btn"
                @click="emit('toggle-menu')"></button>
      </div>
      <nav class="menu__nav">
        <ul class="menu__nav-list">
          <li class="menu__nav-item"
              v-for="route in routes"
              :key="route.path">
            <NuxtLink :to="route.path"
                      class="menu__nav-link"
                      :class="{
                        'menu__nav-link--open': $route.path === route.path
                      }"
                      @click="emit('toggle-menu')">
              {{ route.navigationTitle }}
            </NuxtLink>
          </li>
        </ul>
        <ul class="menu__tel-list">
          <li v-for="phone in contactPhones"
              :key="phone.raw"
              class="menu__tel-item">
            <a :href="`tel:+7${phone.raw}`"
               class="decoration-none">{{ phone.formatted }}</a>
          </li>
        </ul>
        <BackCallButton class="btn menu__btn" />
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BackCallButton } from '../../../Popups';
import type { ITheAppHeaderMenuProps } from './types';

const { routes } = useAppConfig();
const $route = useRoute();
const props = defineProps({
  phones: {
    type: Array as PropType<ITheAppHeaderMenuProps['phones']>,
    required: true,
  },
  isMenuOpened: {
    type: Boolean as PropType<ITheAppHeaderMenuProps['isMenuOpened']>,
    required: true,
  },
});

const contactPhones = useContactPhones(props.phones);
const emit = defineEmits(['toggle-menu']);
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
../../../Popups
