<template>
  <div id="app-layout">
    <TheHeader />
    <slot />
    <TheFooter />
    <BackCallPopup />
    <SuccessPopup />
  </div>
</template>

<script setup lang="ts">
import {
  TheHeader,
  TheFooter,
  BackCallPopup,
  SuccessPopup,
} from '@/components';

const contactsStore = useContactsStore();

onMounted(() => {
  contactsStore.getAll();
});
</script>

<style lang="scss" scoped>
#app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}
</style>
