<template>
  <section class="pop-up"
           :class="isOpened ? 'pop-up__is-open' : ''">
    <div class="pop-up__align">
      <div class="back-call back-call-accepted">
        <button class="pop-up__close-btn"
                @click="$popupEventBus.emit('close-success-popup')"></button>
        <h2 class="pop-up__title pop-up__title-accepted">
          {{ $t('popups.successPopup.title') }}
        </h2>
        <p class="pop-up__accepted-description">
          {{ $t('popups.successPopup.description') }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const eventBus = usePopupEventBus();

const isOpened = ref(false);

eventBus.on('open-success-popup', () => isOpened.value = true);
eventBus.on('close-success-popup', () => isOpened.value = false);
</script>

<style lang="scss" scoped>
@import "../popup";
@import "../back-call";
</style>
