<template>
  <button @click="onClick">
    {{ $t('app.callButton') }}
  </button>
</template>

<script setup lang="ts">
const eventBus = usePopupEventBus();

function onClick() {
  eventBus.emit('open-back-call-form-popup');
}
</script>
